<template>
    <NuxtImg loading="lazy" :[attrKey]="attrValue" :alt="alt" :class="[classes]" @load="onLoad" />
</template>

<script setup>
    const props = defineProps({
        src: {
            type: String,
            default: ""
        },
        srcset: {
            type: String,
            default: ""
        },
        alt: {
            type: String,
            default: ""
        },
        classes: {
            type: String,
            default: "responsive"
        }
    });

    const { $ScrollTrigger: ScrollTrigger } = useNuxtApp();
    const attrKey = computed(() => (props.src !== "" ? "src" : "srcset"));
    const attrValue = computed(() => (props.src !== "" ? props.src : props.srcset));

    const onLoad = async () => ScrollTrigger.refresh(true);
</script>
